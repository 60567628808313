const host = process.env.VUE_APP_GSAPI;
const key = process.env.VUE_APP_GSAPI_KEY;
import api from '@/api/api';

export default class PolicyIssuance {
    application_date = "";
    location = "";
    start_date = "";
    end_date = "";
    is_renewal = "";
    retainer_number = "";
    type_of_delivery = "";
    applicant_information = {
        name: "",
        middle_name: "",
        paternal_name: "",
        maternal_name: "",
        rfc: "",
        curp: "",
        street: "",
        number_ext: "",
        number_int: "",
        suburb: "",
        city: "",
        state: "",
        municipality: "",
        postal_code: "",
        cellphone: "",
        phone: "",
        place_of_birth: "",
        date_of_birth: "",
        age: "",
        gender: "",
        marital_status: "",
        annual_income: "",
        email: "",
        occupation: "",
        description_occupation: "",
        company_name: "",
        workplace: "",
        type_of_activity: "",
        renewable_beneficiaries: false
    };
    contracting_party = {
        name: "",
        middle_name: "",
        paternal_name: "",
        maternal_name: "",
        rfc: "",
        curp: "",
        street: "",
        number_ext: "",
        number_int: "",
        suburb: "",
        city: "",
        state: "",
        municipality: "",
        postal_code: "",
        cellphone: "",
        phone: "",
        email: "",
        annual_income: "",
        occupation: "",
        company_name: "",
        relationship_with_applicant: ""
    };
    coverage_information = {
        vitalicio: false,
        vitalicio_assured_amount: 0,
        vitalicio_national_currency: true,
        vitalicio_term_reached_age: true,
        vitalicio_term_years_of_insurance: 0,
        vitalicio_term_years_of_payment: 0,
        dotal: false,
        dotal_number_months: 0,
        dotal_monthly_price: 0,
        bit: true,
        bit_disability_and_permanent_payments: true,
        bpai: true,
        bpai_assured_amount: 0,
        ma: true,
        ma_assured_amount: 0,
        di: true,
        di_assured_amount: 0,
        ti: true,
        ti_assured_amount: 0,
        eg: true,
        eg_assured_amount: 0,
        cpf: false,
        cpf_assured_amount: 0,
        provisional: true,
        provisional_coverage_name: "0",
        provisional_agent_key: "12434",
        provisional_folio_number: "",
        provisional_date: "",
        provisional_assured_amount: 0,
        provisional_names: "",
        provisional_paternal_name: "",
        provisional_maternal_name: "",
        payment_frequency: "",
        payment_method: "",
        retainer: ""
    };
    beneficiaries = [];
    health_questions = {
        is_smoker: false,
        type_smoker: "",
        amount_smoker: "",
        frequency_smoker: "",
        date_since_you_quit_smoking: "",
        use_drugs: false,
        using_drugs_since: "",
        drug_type: "",
        drug_frequency: "",
        last_time_using_drugs: "",
        received_treatment_related_to_drug_use: false,
        is_alcoholic: false,
        amount_of_alcoholic_beverage: "",
        frequency_of_alcoholic_beverage: "",
        date_since_stopped_drinking_alcohol: "",
        p1_height: 0,
        p2_weight: 0,
        p8_had_surgery: true,
        p12_pregnancy: true,
        p12_have_had_preeclampsia: true,
        p12_have_had_eclampsia: true,
        p12_have_had_an_embarazo_ectopico_etc: true
    };
    documents = {
        identificacion_oficial: false,
        identificacion_oficial_url: "",
        comprobante_domicilio: false,
        comprobante_domicilio_url: "",
        calidad_migratoria: false,
        calidad_migratoria_url: "",
        comprobante_incripcion: false,
        comprobante_incripcion_url: "",
        curp: false,
        curp_url: "",
        rfc: false,
        rfc_url: ""
    };
    agent_details = {
        info1: {
            name: "",
            office: "",
            key_code: 0,
            participation: "",
            promotoria: ""
        },
        observations: "",
        p1_date_of_knowing_the_applicant: "",
        p2_identity_verification_type: "",
        p3_completed_client_request: false,
        p3_negative_answer: "",
        p4_recommended_client: false,
        p4_negative_answer: ""
    };
    quote_information = {
        age: 0,
        gender: "",
        is_smoker: true,
        assured_amount: 0,
        coverages: [],
        diseases: [],
        occupations: [],
        sports: [],
        activities: [],
        weight: 0,
        height: 0
    };
    
    constructor(application_date, location, start_date, end_date, is_renewal, retainer_number, type_of_delivery, applicant_information, contracting_party, coverage_information, beneficiaries, health_questions, documents, agent_details, quote_information){
        this.application_date = (application_date!== undefined)? application_date: this.application_date;
        this.location = (location!== undefined)? location: this.location;
        this.start_date = (start_date!== undefined)? start_date: this.start_date;
        this.end_date = (end_date!== undefined)? end_date: this.end_date;
        this.is_renewal = (is_renewal!== undefined)? is_renewal: this.is_renewal;
        this.retainer_number = (retainer_number!== undefined)? retainer_number: this.retainer_number;
        this.type_of_delivery = (type_of_delivery!== undefined)? type_of_delivery: this.type_of_delivery;
        this.applicant_information = (applicant_information!== undefined)? applicant_information: this.applicant_information;
        this.contracting_party = (contracting_party!== undefined)? contracting_party: this.contracting_party;
        this.coverage_information = (coverage_information!== undefined)? coverage_information: this.coverage_information;
        this.beneficiaries = (beneficiaries!== undefined)? beneficiaries: this.beneficiaries;
        this.health_questions = (health_questions!== undefined)? health_questions: this.health_questions;
        this.documents = (documents!== undefined)? documents: this.documents;
        this.agent_details = (agent_details!== undefined)? agent_details: this.agent_details;
        this.quote_information = (quote_information!== undefined)? quote_information: this.quote_information;
    }
    
    set applicantInformation(values){
        try {
            if(values !== ''){
                this.applicant_information.name = values.name;
                this.applicant_information.middle_name = values.middle_name;
                this.applicant_information.paternal_name = values.paternal_name;
                this.applicant_information.maternal_name = values.maternal_name;
                this.applicant_information.rfc = values.rfc;
                this.applicant_information.curp = values.curp;
                this.applicant_information.street = values.street;
                this.applicant_information.number_ext = values.number_ext;
                this.applicant_information.number_int = values.number_int;
                this.applicant_information.suburb = values.suburb;
                this.applicant_information.city = values.city;
                this.applicant_information.state = values.state;
                this.applicant_information.municipality = values.municipality;
                this.applicant_information.postal_code = values.postal_code;
                this.applicant_information.cellphone = values.cellphone;
                this.applicant_information.phone = values.phone;
                this.applicant_information.place_of_birth = values.place_of_birth;
                this.applicant_information.date_of_birth = values.date_of_birth;
                this.applicant_information.age = values.age;
                this.applicant_information.gender = values.gender;
                this.applicant_information.marital_status = values.marital_status;
                this.applicant_information.annual_income = values.annual_income;
                this.applicant_information.email = values.email;
                this.applicant_information.occupation = values.occupation;
                this.applicant_information.description_occupation = values.description_occupation;
                this.applicant_information.company_name = values.company_name;
                this.applicant_information.workplace = values.workplace;
                this.applicant_information.type_of_activity = values.type_of_activity;
                this.applicant_information.renewable_beneficiaries = values.renewable_beneficiaries;
            }else{
                throw '¡Estructura de applicant_information incompleta!';
            }
        } catch(err) {
            console.log('Error:', err)
        }
        
    }

    get applicantInformation(){
        return this.contracting_party;
    }

    set contractingParty(values){
        try {
            if(values !== ''){
                this.contracting_party.name = values.name;
                this.contracting_party.middle_name = values.middle_name;
                this.contracting_party.paternal_name = values.paternal_name;
                this.contracting_party.maternal_name = values.maternal_name;
                this.contracting_party.rfc = values.rfc;
                this.contracting_party.curp = values.curp;
                this.contracting_party.street = values.street;
                this.contracting_party.number_ext = values.number_ext;
                this.contracting_party.number_int = values.number_int;
                this.contracting_party.suburb = values.suburb;
                this.contracting_party.city = values.city;
                this.contracting_party.state = values.state;
                this.contracting_party.municipality = values.municipality;
                this.contracting_party.postal_code = values.postal_code;
                this.contracting_party.cellphone = values.cellphone;
                this.contracting_party.phone = values.phone;
                this.contracting_party.email = values.email;
                this.contracting_party.annual_income = values.annual_income;
                this.contracting_party.occupation = values.occupation;
                this.contracting_party.company_name = values.company_name;
                this.contracting_party.relationship_with_applicant = values.relationship_with_applicant;
            }else{
                throw '¡Estructura de contracting_party incompleta!';
            }
        } catch(err) {
            console.log('Error:', err)
        }
        
    }

    get contractingParty(){
        return this.contracting_party;
    }

    set coverageInformation(values){
        try {
            if(values !== ''){
                this.coverage_information.vitalicio = values.vitalicio;
                this.coverage_information.vitalicio_assured_amount = values.vitalicio_assured_amount;
                this.coverage_information.vitalicio_national_currency = values.vitalicio_national_currency;
                this.coverage_information.vitalicio_term_reached_age = values.vitalicio_term_reached_age;
                this.coverage_information.vitalicio_term_years_of_insurance = values.vitalicio_term_years_of_insurance;
                this.coverage_information.vitalicio_term_years_of_payment = values.vitalicio_term_years_of_payment;
                this.coverage_information.dotal = values.dotal;
                this.coverage_information.dotal_number_months = values.dotal_number_months;
                this.coverage_information.dotal_monthly_price = values.dotal_monthly_price;
                this.coverage_information.bit = values.bit;
                this.coverage_information.bit_disability_and_permanent_payments = values.bit_disability_and_permanent_payments;
                this.coverage_information.bpai = values.bpai;
                this.coverage_information.bpai_assured_amount = values.bpai_assured_amount;
                this.coverage_information.ma = values.ma;
                this.coverage_information.ma_assured_amount = values.ma_assured_amount;
                this.coverage_information.di = values.di;
                this.coverage_information.di_assured_amount = values.di_assured_amount;
                this.coverage_information.ti = values.ti;
                this.coverage_information.ti_assured_amount = values.ti_assured_amount;
                this.coverage_information.eg = values.eg;
                this.coverage_information.eg_assured_amount = values.eg_assured_amount;
                this.coverage_information.cpf = values.cpf;
                this.coverage_information.cpf_assured_amount = values.cpf_assured_amount;
                this.coverage_information.provisional = values.provisional;
                this.coverage_information.provisional_coverage_name = values.provisional_coverage_name;
                this.coverage_information.provisional_agent_key = values.provisional_agent_key;
                this.coverage_information.provisional_folio_number = values.provisional_folio_number;
                this.coverage_information.provisional_date = values.provisional_date;
                this.coverage_information.provisional_assured_amount = values.provisional_assured_amount;
                this.coverage_information.provisional_names = values.provisional_names;
                this.coverage_information.provisional_paternal_name = values.provisional_paternal_name;
                this.coverage_information.provisional_maternal_name = values.provisional_maternal_name;
                this.coverage_information.payment_frequency = values.payment_frequency;
                this.coverage_information.payment_method = values.payment_method;
                this.coverage_information.retainer = values.retainer;
            }else{
                throw '¡Estructura de contracting_party incompleta!';
            }
        } catch(err) {
            console.log('Error:', err)
        }
        
    }

    get coverageInformation(){
        return this.coverage_information;
    }

    set healthQuestions(values){
        try {
            if(values !== ''){
                this.health_questions.is_smoker = values.is_smoker;
                this.health_questions.type_smoker = values.type_smoker;
                this.health_questions.amount_smoker = values.amount_smoker;
                this.health_questions.frequency_smoker = values.frequency_smoker;
                this.health_questions.date_since_you_quit_smoking = values.date_since_you_quit_smoking;
                this.health_questions.use_drugs = values.use_drugs;
                this.health_questions.using_drugs_since = values.using_drugs_since;
                this.health_questions.drug_type = values.drug_type;
                this.health_questions.drug_frequency = values.drug_frequency;
                this.health_questions.last_time_using_drugs = values.last_time_using_drugs;
                this.health_questions.received_treatment_related_to_drug_use = values.received_treatment_related_to_drug_use;
                this.health_questions.is_alcoholic = values.is_alcoholic;
                this.health_questions.amount_of_alcoholic_beverage = values.amount_of_alcoholic_beverage;
                this.health_questions.frequency_of_alcoholic_beverage = values.frequency_of_alcoholic_beverage;
                this.health_questions.date_since_stopped_drinking_alcohol = values.date_since_stopped_drinking_alcohol;
                this.health_questions.p1_height = values.p1_height;
                this.health_questions.p2_weight = values.p2_weight;
                this.health_questions.p8_had_surgery = values.p8_had_surgery;
                this.health_questions.p12_pregnancy = values.p12_pregnancy;
                this.health_questions.p12_have_had_preeclampsia = values.p12_have_had_preeclampsia;
                this.health_questions.p12_have_had_eclampsia = values.p12_have_had_eclampsia;
                this.health_questions.p12_have_had_an_embarazo_ectopico_etc = values.p12_have_had_an_embarazo_ectopico_etc;
            }else{
                throw '¡Estructura de health_questions incompleta!';
            }
        } catch(err) {
            console.log('Error:', err)
        }
        
    }

    get healthQuestions(){
        return this.health_questions;
    }

    set documents(values){
        try {
            if(values !== ''){
                this.documents.identificacion_oficial = values.identificacion_oficial;
                this.documents.identificacion_oficial_url = values.identificacion_oficial_url;
                this.documents.comprobante_domicilio = values.comprobante_domicilio;
                this.documents.comprobante_domicilio_url = values.comprobante_domicilio_url;
                this.documents.calidad_migratoria = values.calidad_migratoria;
                this.documents.calidad_migratoria_url = values.calidad_migratoria_url;
                this.documents.comprobante_incripcion = values.comprobante_incripcion;
                this.documents.comprobante_incripcion_url = values.comprobante_incripcion_url;
                this.documents.curp = values.curp;
                this.documents.curp_url = values.curp_url;
                this.documents.rfc = values.rfc;
                this.documents.rfc_url = values.rfc_url;
            }else{
                throw '¡Estructura de documents incompleta!';
            }
        } catch(err) {
            console.log('Error:', err)
        }
        
    }

    get documents(){
        return this.health_questions;
    }

    set agentDetails(values){
        try {
            if(values !== ''){
                this.agent_details.info1 = {
                    name: values.info1.name,
                    office: values.info1.office,
                    key_code: values.info1.key_code,
                    participation: values.info1.participation,
                    promotoria: values.info1.promotoria
                };
                this.agent_details.observations = values.observations;
                this.agent_details.p1_date_of_knowing_the_applicant = values.p1_date_of_knowing_the_applicant;
                this.agent_details.p2_identity_verification_type = values.p2_identity_verification_type;
                this.agent_details.p3_completed_client_request = values.p3_completed_client_request;
                this.agent_details.p3_negative_answer = values.p3_negative_answer;
                this.agent_details.p4_recommended_client = values.p4_recommended_client;
                this.agent_details.p4_negative_answer = values.p4_negative_answer;
            }else{
                throw '¡Estructura de agent_details incompleta!';
            }
        } catch(err) {
            console.log('Error:', err)
        }
        
    }

    get agentDetails(){
        return this.agent_details;
    }

    set quoteInformation(values){
        try {
            if(values !== ''){
                this.quote_information.age = values.age,
                this.quote_information.gender = values.gender,
                this.quote_information.is_smoker = values.is_smoker,
                this.quote_information.assured_amount = values.assured_amount,
                this.quote_information.coverages = values.coverages,
                this.quote_information.diseases = values.diseases,
                this.quote_information.occupations = values.occupations,
                this.quote_information.sports = values.sports,
                this.quote_information.activities = values.activities,
                this.quote_information.weight = values.weight,
                this.quote_information.height = values.height
            }else{
                throw '¡Estructura de agent_details incompleta!';
            }
        } catch(err) {
            console.log('Error:', err)
        }
        
    }

    get quoteInformation(){
        return this.quote_information;
    }

    create = async(token, params) => {

        const resource = `/api/v1/vs/application/create`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            }
        };

        return await api.httpPost(API_URL, config, params);
    }

    getToken = async() => {
        const resource = `/token/generate`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            apikey: key
        };

        return await api.httpGet(API_URL, config , params);
    }

    uploadFiles = async (token, files) => {

        const resource = '/documents/upload'
        const API_URL = `${host}${resource}`

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            }
        };

        const params = {
            documents: files,
            process_name: "vida_simple"
        }

        try {
            return await api.httpPost(API_URL, config, params);
        } catch (error) {
            throw error.response;
        }

    }

    

    deleteFiles = async (token, files) => {
        const resource = '/documents/delete';
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            }
        };

        const params = {
            documents_urls: files,
            process_name: "vida_simple"
        }

        try {
            return await api.httpPost(API_URL, config, params);
        } catch (error) {
            throw error.response;
        }
    }

    post = async (token, data) => {
        
        const resource = '/vs/application/create';
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            }
        };

        const params = data;

        try {
            return await api.httpPost(API_URL, config, params);
        } catch (error) {
            throw error.response;
        }
    } 
}